import 'jquery';
import 'jquery-countdown';
import 'jquery-validation';
import 'headroom.js';
//import 'jarallax';


import $ from 'jquery';
import Headroom from 'headroom.js';
window.jQuery = $;
window.$ = $;

window.Headroom = Headroom;


console.log('The \'validation\' bundle has been loaded!');